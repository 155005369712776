import { Component, OnInit } from '@angular/core';
import { AuthService } from "src/app/services/auth.service";
import { Settingdata } from "src/app/services/settings";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

declare var $: any;
declare const showLoader: any;
declare const hideLoader: any;

@Component({
  selector: 'app-privacy-and-policy',
  templateUrl: './privacy-and-policy.component.html',
  styleUrls: ['./privacy-and-policy.component.css']
})
export class PrivacyAndPolicyComponent implements OnInit {
  isLogin: Boolean;
  policy: SafeResourceUrl;
  selectedUser:any;
  contentForPrivacy: string = '2';

  constructor(
    private authService: AuthService,
    private settingdata: Settingdata,
    private toastrService: ToastrNotificationService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    if(this.authService.isLoggedIn()==true){
      this.isLogin = true;
      this.selectedUser =  localStorage.getItem("SelectedUser");
    }else{
      this.isLogin = false;
    }
    
    showLoader();
    this.settingdata.getTermsPrivacy(this.contentForPrivacy).subscribe(
      result => {
        hideLoader();
        
        if (result.data && result.data.policy) {
          this.policy = result.data.policy;
        } else {
          this.toastrService.showError('No policy URL found.', 'Error');
        }
      },
      err => {
        hideLoader();
        this.toastrService.showError(err.error.message, 'Error');
      }
    );
  }

}
